import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Engage = () => (
  <Layout>
    <SEO title="Engage" />
    <div className="container">
      <h1>Engage</h1>
      <p>
      Direct Debit Information for contributions:
Bank: Commonwealth Bank
Name of the Account: Friends of I EM Australia
Account No: 1469 0324
BSB No: 064 000
In the reference column, please include your surname and the State you live and send
an email to Treasurer: treasurer@fiema.org for receipting purposes.
Address: Friends of IEM Australia, PO Box 4560
Eight Mile Plains, QLD 4113
devasahayam@hotmail.com
      </p>
    </div>
  </Layout>
)

export default Engage
